.legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 25px;
}

.key-name {
  flex: 0 1 3rem;
  text-align: center;
  font-weight: bold;
}

.action {
  flex: 0 1 10rem;
}
