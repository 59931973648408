:root {
  --screen-height: 100vh;
  --scoreboard-height: 50px;
  --buttons-container-height: min(85px, calc(100vw / 5), calc(var(--screen-height) / 6));
  --rows: 21;
  --columns: 10;
  --sidebar-min-width: 95px;
  --gameboard-width: min(
    calc(
      (var(--screen-height) - (var(--scoreboard-height) + var(--buttons-container-height))) /
        (var(--rows) / var(--columns))
    ),
    100vw
  );
  --button-diameter: var(--buttons-container-height);
  --button-max-diameter: var(--buttons-container-height);
  --transition-time: 10s;
  --dark-box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

@media (min-width: 640px) {
  :root {
    --gameboard-width: min(
      calc(var(--screen-height) / (var(--rows) / var(--columns))),
      calc(100vw - (var(--sidebar-min-width) * 2))
    );
    --sidebar-width: calc((100vw - var(--gameboard-width)) / 2);
    --button-diameter: var(--sidebar-width);
    --button-max-diameter: min(95px, var(--sidebar-width), calc(var(--screen-height) / 5));
    --thumb-length: 150px;
  }
}

html {
  -webkit-touch-callout: none; /* Safari Touch */
  -webkit-user-select: none; /* Webkit */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge */
  user-select: none;
}

body {
  margin: 0;
  font-family: Arial;
}

.sidebar {
  display: none;
  min-width: var(--sidebar-min-width);
  width: var(--sidebar-width);
  box-sizing: border-box;
}

@media (min-width: 640px) {
  .sidebar {
    display: flex;
  }
}
