.round-button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: var(--button-diameter);
  max-width: var(--button-max-diameter);
  height: var(--button-diameter);
  max-height: var(--button-max-diameter);
  box-shadow: var(--dark-box-shadow);
}

.round-button > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--button-diameter) - 15px);
  height: calc(var(--button-diameter) - 15px);
}

.rotate > * {
  transform: translateY(-6px);
  font-size: var(--button-diameter);
}

@media (min-width: 640px) {
  .rotate > * {
    font-size: 65px;
  }

  @media (min-height: 495px) {
    .rotate > * {
      font-size: 100px;
    }
  }
}

.move > * {
  font-size: calc(var(--button-diameter) - 15px);
}

@media (min-width: 640px) {
  .move > * {
    font-size: 45px;
    transform: translateY(2px);
  }

  @media (min-height: 495px) {
    .move > * {
      font-size: 65px;
    }
  }
}
