.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
}

.page-container::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  transition: opacity var(--transition-time);
}

.before-is-visible::before {
  opacity: 1;
}

.before-is-hidden::before {
  opacity: 0;
}

.superman,
.superman-before::before {
  background-image: linear-gradient(to right, #0099f7, #f11712);
}

.orange-coral,
.orange-coral-before::before {
  background-image: linear-gradient(to right, #ff9966, #ff5e62);
}

.deep-sea,
.deep-sea-before::before {
  background-image: linear-gradient(to right, #2c3e50, #4ca1af);
}

.sunrise,
.sunrise-before::before {
  background-image: linear-gradient(to right, #ff512f, #f09819);
}

.fresh-air,
.fresh-air-before::before {
  background-image: linear-gradient(
    95.2deg,
    rgba(173, 252, 234, 1) 26.8%,
    rgba(192, 229, 246, 1) 64%
  );
}

.cherry-blossom,
.cherry-blossom-before::before {
  background-image: linear-gradient(25deg, #d64c7f, #ee4758 50%);
}

.mango,
.mango-before::before {
  background-image: radial-gradient(circle farthest-side, #fceabb, #f8b500);
}

.chlorophyll,
.chlorophyll-before::before {
  background-image: radial-gradient(
    circle 759px at -6.7% 50%,
    rgba(80, 131, 73, 1) 0%,
    rgba(140, 209, 131, 1) 26.2%,
    rgba(178, 231, 170, 1) 50.6%,
    rgba(144, 213, 135, 1) 74.1%,
    rgba(75, 118, 69, 1) 100.3%
  );
}

.spectrum,
.spectrum-before::before {
  background-image: linear-gradient(to right, #c6ffdd, #fbd786, #f7797d);
}

.not-dead-red,
.not-dead-red-before::before {
  background-image: linear-gradient(to right, #fffbd5, #b20a2c);
}

.sand-to-sea,
.sand-to-sea-before::before {
  background-image: linear-gradient(to right, #decba4, #3e5151);
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

@media (min-width: 640px) {
  .game-container {
    flex-direction: row;
    align-items: stretch;
  }
}
