.pause-button {
  border-radius: 5%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  margin: 0 15px;
  transition: box-shadow var(--transition-time);
}

@media (min-width: 640px) {
  .pause-button {
    box-shadow: var(--dark-box-shadow);
  }
}

.pause-icon {
  transform: rotate(90deg);
  font-size: 50px;
  font-weight: 900;
  line-height: 30px;
}

.play-icon {
  font-size: 30px;
  line-height: 30px;
  transform: translateY(3px);
}
